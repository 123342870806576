import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilTrash } from '@coreui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addPackage, deletePackage, getPackages, updatePackage } from 'src/redux/packages'
import { AppSpinner } from 'src/components'
import { calculateDiscountedAmount } from 'src/utils/calculateDiscountedAmount'

const PackageUpgrade = () => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    package_name: '',
    package_amount: '',
    package_duration: '',
    package_discount: '',
  })

  const dispatch = useDispatch()

  const { packages, isLoading } = useSelector((state) => {
    return { packages: state.packages.packages, isLoading: state.packages.loadingPackages }
  })

  useEffect(() => {
    dispatch(getPackages())
  }, [dispatch, loading])

  const handleSubmit = async (dispatchFunction) => {
    console.log(formData)
    if (!formData.package_name) {
      toast('Package name required!', { type: 'info' })
    } else if (!formData.package_amount) {
      toast('Package amount required!', { type: 'info' })
    } else if (!formData.package_duration) {
      toast('Package duration required!', { type: 'info' })
    } else {
      setLoading(true)
      try {
        await dispatch(dispatchFunction)
        setFormData({
          package_name: '',
          package_amount: '',
          package_duration: '',
          package_discount: '',
        })
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleDelete(id) {
    setLoading(true)
    await dispatch(deletePackage(id))
    setLoading(false)
  }

  console.log(packages?.upgrade)

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={8}>
            {isLoading ? (
              <CContainer className="d-flex justify-content-center align-items-center">
                <AppSpinner />
              </CContainer>
            ) : (
              <>
                {Array.isArray(packages.upgrade) && packages?.upgrade?.length === 0 ? (
                  <h3>Package is empty. Please create a new package.</h3>
                ) : (
                  <CTable align="middle" className="mb-0 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell className="text-center"></CTableHeaderCell>
                        <CTableHeaderCell>Package name</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">
                          Package Duration
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Package amount</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">
                          Package Discount
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Package Value</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>

                    <CTableBody>
                      {packages?.upgrade?.map((item, index) => (
                        <CTableRow v-for="item in tableItems" key={index}>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <small className="text-medium-emphasis">{index + 1}) </small>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <div className="text-medium-emphasis">
                              <strong>{item.package_name}</strong>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="text-medium-emphasis">{item.package_duration}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="text-medium-emphasis">
                                {parseInt(item.package_amount)}
                              </h6>
                            </div>
                          </CTableDataCell>

                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="text-medium-emphasis">{item.package_discount ?? 0}</h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <div className="clearfix">
                              <h6 className="text-medium-emphasis">
                                {calculateDiscountedAmount(
                                  item.package_amount * 1,
                                  item.package_discount,
                                )}
                              </h6>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                              <CButton color="warning" size="sm" onClick={() => setFormData(item)}>
                                <CIcon icon={cilPencil} className="text-white" />
                              </CButton>
                              <CButton
                                color="danger"
                                size="sm"
                                onClick={() => handleDelete(item.id)}
                              >
                                <CIcon icon={cilTrash} className="text-white" />
                              </CButton>
                            </CButtonGroup>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                )}
              </>
            )}
          </CCol>
          <CCol md={9} lg={7} xl={4}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm>
                  <h5 className="text-medium-emphasis mb-3">Add Package</h5>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Package name"
                      autoComplete="Package name"
                      value={formData.package_name}
                      onChange={(e) => setFormData({ ...formData, package_name: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="number"
                      placeholder="Package amount"
                      autoComplete="Package amount"
                      value={formData.package_amount}
                      onChange={(e) => setFormData({ ...formData, package_amount: e.target.value })}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      placeholder="Duration"
                      autoComplete="Duration"
                      value={formData.package_duration}
                      onChange={(e) =>
                        setFormData({ ...formData, package_duration: e.target.value })
                      }
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CFormInput
                      type="number"
                      placeholder="Discount (Precentage)"
                      max="99"
                      min="0"
                      value={formData.package_discount}
                      onChange={(e) =>
                        setFormData({ ...formData, package_discount: e.target.value })
                      }
                    />
                  </CInputGroup>
                  {formData.package_discount * 1 >= 100 && formData.package_discount ? (
                    <div className="text-danger small mb-2">
                      Discount value must be smaller than 100%
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="d-grid">
                    <CButton
                      color="success"
                      onClick={() => {
                        formData?.id
                          ? handleSubmit(updatePackage(formData?.id, formData))
                          : handleSubmit(addPackage(formData))
                      }}
                      disabled={formData.package_discount * 1 >= 100}
                    >
                      {formData?.id ? 'Update' : 'Submit'}
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default PackageUpgrade
