import { CButton, CForm, CFormInput } from '@coreui/react'
import React, { useState } from 'react'
import './SendNumber.css'

const SendNumber = () => {
  const [formValues, setFormValues] = useState([
    { id: 1, number: '', name: '', age: '', status: '', city: '' },
    { id: 2, number: '', name: '', age: '', status: '', city: '' },
    { id: 3, number: '', name: '', age: '', status: '', city: '' },
  ])

  const handleInputChange = (id, field, value) => {
    const updatedFormValues = formValues.map((item) =>
      item.id === id ? { ...item, [field]: value } : item,
    )
    setFormValues(updatedFormValues)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Form submitted:', formValues)
  }

  return (
    <CForm onSubmit={handleSubmit}>
      {formValues.map((item, index) => (
        <div className="grid" key={index}>
          <CButton className="custom">View Profile</CButton>
          <CFormInput
            className="custom"
            type="text"
            placeholder="1st Number"
            value={item.number}
            onChange={(e) => handleInputChange(item.id, 'number', e.target.value)}
          />
          <CFormInput
            className="custom"
            type="text"
            placeholder="Name"
            value={item.name}
            onChange={(e) => handleInputChange(item.id, 'name', e.target.value)}
          />
          <CFormInput
            className="custom"
            type="text"
            placeholder="Age"
            value={item.age}
            onChange={(e) => handleInputChange(item.id, 'age', e.target.value)}
          />
          <CFormInput
            className="custom"
            type="text"
            placeholder="Status"
            value={item.status}
            onChange={(e) => handleInputChange(item.id, 'status', e.target.value)}
          />
          <CFormInput
            className="custom"
            type="text"
            placeholder="City"
            value={item.city}
            onChange={(e) => handleInputChange(item.id, 'city', e.target.value)}
          />
        </div>
      ))}
      <CButton type="submit" className="button">
        Send
      </CButton>
    </CForm>
  )
}

export default SendNumber
