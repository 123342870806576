import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Login from './views/pages/login/Login'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'
import DeletionRequest from './views/pages/privacy/DeletionRequest'
import PrivacyPolicy from './views/pages/privacy/PrivacyPolicy'
import DefaultLayout from './layout/DefaultLayout'
import TnCs from './views/pages/t&c/TnCs'
import ContactUs from './views/pages/contactus/ContactUs'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const isTokenValid = () => {
  const token = localStorage.getItem('token')
  return !!token && !isTokenExpired(token)
}

const isTokenExpired = (token) => {
  const decodedToken = decodeToken(token)
  const expiryDate = decodedToken.exp * 1000
  return Date.now() >= expiryDate
}

const decodeToken = (token) => {
  return JSON.parse(atob(token.split('.')[1]))
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/delete" name="Deletion Request" element={<DeletionRequest />} />
            <Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />
            <Route exact path="/term-conditions" name="Terms and Conditions" element={<TnCs />} />
            <Route exact path="/contactus" name="Contact Us" element={<ContactUs />} />
            <Route
              path="*"
              element={isTokenValid() ? <DefaultLayout /> : <Navigate to="/login" />}
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
