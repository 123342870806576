import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cibShowpad,
  cilBell,
  cilMoney,
  cilSpeedometer,
  cilStar,
  cilUser,
  cilUserPlus,
  cilUserX,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    component: CNavTitle,
    name: 'users',
  },
  {
    component: CNavItem,
    name: 'App User',
    to: '/users/all',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Block User',
  //   to: '/users/block',
  //   icon: <CIcon icon={cilUserX} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Admin User',
    to: '/users/admin',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavTitle,
  //   name: 'Master',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Master Data',
  //   icon: <CIcon icon={cilLibraryAdd} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Religion',
  //       to: '/master/religion',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Sect',
  //       to: '/master/sect',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Community',
  //       to: '/master/community',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Caste',
  //       to: '/master/caste',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Father Status',
  //       to: '/master/father-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Mother Status',
  //       to: '/master/mother-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Brother Status',
  //       to: '/master/brother-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Sister Status',
  //       to: '/master/sister-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Married Status',
  //       to: '/master/married-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Height',
  //       to: '/master/height',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Education Level',
  //       to: '/master/education-level',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Degree Title',
  //       to: '/master/degree-title',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Working Sector',
  //       to: '/master/working-sector',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Living Status',
  //       to: '/master/living-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Residence Status',
  //       to: '/master/residence-status',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Residence Size',
  //       to: '/master/residence-size',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Skintone',
  //       to: '/master/skintone',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Report Title',
  //       to: '/master/report-title',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Marital Status',
  //       to: '/master/marital-status',
  //     },
  //   ],
  // },
  {
    component: CNavTitle,
    name: 'Reports',
  },
  {
    component: CNavGroup,
    name: 'Reports',
    icon: <CIcon icon={cibShowpad} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Reports Users',
        to: '/reports/users',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Packages',
  },
  {
    component: CNavGroup,
    name: 'Packages',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Upgrade Package',
        to: '/package/upgrade',
      },
      {
        component: CNavItem,
        name: 'Subscription',
        to: '/package/users',
      },
      {
        component: CNavItem,
        name: 'Package Approval',
        to: '/package/approval',
      },
      {
        component: CNavItem,
        name: 'Send Numbers',
        to: '/package/sendnumber',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'payment method',
  },
  {
    component: CNavGroup,
    name: 'Payments',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Show Bank Details',
        to: '/payment/details',
      },
      {
        component: CNavItem,
        name: 'Transaction History',
        to: '/payment/history',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Custom Notification',
  },
  {
    component: CNavItem,
    name: 'Send Notification',
    to: '/notification',
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  },
]

export default _nav
